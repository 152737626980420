import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import EventOverviewCard from '../components/EventOverviewCard'
import { CardDeck } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { now, timestampUnix } from '../components/dateFn'
import OneColSection from '../components/OneColSection'

function Zeremonien(props) {

  

  let futureZeremonien = []

    // pass some values from the page templete to the body
    for (let index = 0; index < props.data.prismic.allPages.edges.length; index++) {
        const element = props.data.prismic.allPages.edges[index];

      //get only concerts in the future
      const endTimeUnix = timestampUnix(element.node.body[0].primary.end_time)

      if (element.node.body[0].primary.event_type === "Zeremonien" && endTimeUnix > now) {
        futureZeremonien.push(element)
      }

        element.node.body[0].primary.slug = element.node._meta.uid;
        element.node.body[0].primary.pageTitle = element.node.page_title;
    }

    return (
        <Layout>
            <>
                <PageTitle pageTitle={props.data.prismic.allZeremoniens.edges[0].node.page_title} />
          <OneColSection content={props.data.prismic.allZeremoniens.edges[0].node.text} />

          {futureZeremonien.length > 0 ? 
                <CardDeck>
                    {futureZeremonien.sort((a, b) => {
                        // Convert the timestamps dates from strings to date objects.
                        let da = new Date(a.node.body[0].primary.date_time),
                            db = new Date(b.node.body[0].primary.date_time);
                        return da - db;
                    }).map((sortedEvent, i) => {
                            return (
                                <EventOverviewCard event={sortedEvent} key={i} />
                            )
                    })}
            </CardDeck> : null}
            </>
        </Layout>
    )
}

export default Zeremonien

export const query = graphql`
  {
    prismic {
      allPages(where: {page_type: "Event", event_type: "Zeremonie"}, lang: "de-ch") {
        totalCount
        edges {
          node {
              page_title
              _meta {
            uid
          }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  event_place
                  event_type
                  image
                  event_teaser
                }
              }
            }
          }
        }
      }
      allZeremoniens(lang: "de-ch") {
        edges {
          node {
            page_title
            text
          }
        }
      }
    }
  }
`
